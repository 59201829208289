;(function($, window, document, app) {
    var $win            = $(window);
    var $doc            = $(document);
    var $header         = $('.header');
    var $parallax       = $('.parallax');
    var $headerBrick    = $('.header').clone();
    var $videoNavSlider = $('.js-videos-nav');

    // Expose global function for maps starting
    window.startMaps = function() {
        $('.map').each(function(){
            var map;
            var center = $(this).data('center');

            map = new google.maps.Map(this, {
                center     : center,
                zoom       : 16,
                scrollwheel: false
            });

            new google.maps.Marker({
                map     : map,
                position: center
            });
        });
    };

    // Function to check if screen is mobile size
    function isMobile() {
        return $win.outerWidth() < 768;
    }

    window.sr = ScrollReveal();
    sr.reveal('section');

    // Append a hidden header for proper offsetting of scrolling links
    $headerBrick
        .addClass('header--compact header--brick')
        .appendTo('.wrapper');

    // Start parallax
    if ($parallax.length) {
        $win
            .on('load scroll', function(){
                $parallax
                    .css({
                        'transform': 'translateY(' + ($win.scrollTop() / 3) + 'px)'
                    });
            });
    }

    // JS scroll links
    $('.js-scroll').on('click', function(e){
        e.preventDefault();

        $('html, body').animate({
            scrollTop: $($(this).attr('href')).offset().top - 90
        });
    });

    // gf ajax
    $('.gf-ajax').each(function(){
        var $this = $(this);
        var validator = $this.validate({
            submitHandler: function(form){
                $.post(ajax_url, $(form).serialize(), function(result){
                    if(result.is_valid){
                        $this.replaceWith(result.confirmation_message);
                    } else {
                        var errors = {};

                        $.each(result.validation_messages, function(key, value){
                            errors['input_' + key] = value;
                        });

                        validator.showErrors(errors);
                    }
                }, 'json');
            }
        });
    });

    $('.nav-toggle').click(function(e) {
        e.preventDefault();
        $(this).parent('li').toggleClass('open');
        $('body').toggleClass('noscroll');
    });

    $('.nav__close').click(function(e) {
        e.preventDefault();
        $('body').toggleClass('noscroll');
    });

    $('.nav-dropdown a').hover(function(e) {
        if(!$(this).hasClass('active')) {
            $('.nav-dropdown a').removeClass('active');
            $(this).addClass('active');
        }
    });

    // JS Videos Nav
    $doc.on('click', '.js-videos-nav a', function(e){
        e.preventDefault();

        var $this 		= $(this);
        var $target     = $($this.attr('href'));
        var activeClass = 'active';

        $this
            .parent()
            .add($target)
            .addClass(activeClass)
            .siblings()
            .each(function(){
                var $sibling = $(this);

                $sibling
                    .removeClass(activeClass)
                    .find('video')
                    .trigger('pause');
            });

        $target
            .find('video')
            .trigger('play');
    });

    // Prevent bootstrap dropdowns to close
    $('[data-noclose]').on('click', function(e){
        e.stopPropagation();
    });

    // Open mobile navigation
    $('.nav-btn').on('click', function(e){
        e.preventDefault();

        $(this)
            .add('.nav')
            .toggleClass('nav-visible');
    });

    // Make img tags backgrounds
    $('.fix-bg').each(function(){
        var $this = $(this);

        $this.css({
            'backgroundImage': 'url(' + $this.find('.background').attr('src') + ')'
        });
    });

    // Video Popups
    $('.js-video-popup').magnificPopup({
        type 	       : 'iframe',
        mainClass      : 'mfp-fade',
        removalDelay   : 300,
        fixedContentPos: true
    });

    $('.inline-popup').magnificPopup({
        type 	       : 'inline',
        mainClass      : 'mfp-fade',
        removalDelay   : 300,
        fixedContentPos: true
    });

    $win
        .on('load scroll', function(){
            var winScrollTop = $win.scrollTop();

            // Fixed header
            $header.toggleClass('header--compact', winScrollTop > 0);
        })
        .on('load resize', function(){
            if (isMobile() && $videoNavSlider.length && !$videoNavSlider.hasClass('owl-carousel')) {
                $videoNavSlider
                    .addClass('owl-carousel')
                    .owlCarousel({
                        items: 1,
                        nav  : true
                    });

                $videoNavSlider.on('translated.owl.carousel', function(){
                    $videoNavSlider
                        .find('.owl-item.active a')
                        .trigger('click');
                });
            } else if ($win.width() > 767 && $videoNavSlider.length && $videoNavSlider.hasClass('owl-carousel')) {
                $videoNavSlider
                    .removeClass('owl-carousel')
                    .trigger('destroy.owl.carousel');
            }
        })
        .on('load', function(){
            if ($('.animate').length) {
                $('.animate').addClass('animated');
            }

            $('.equalizer').equalizer({
                columns : '> div'
            });
        });
})(jQuery, window, document);
